import React from "react";
import HomePage from "./HomePage"; // Importa o componente HomePage

function App() {
  return (
    <div className="App">
      <HomePage /> {/* Renderiza o componente HomePage */}
    </div>
  );
}

export default App;
