import React from "react";

const HomePage: React.FC = () => {
  return (
    <div style={{ backgroundColor: "#f9f9f9", height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Cabeçalho */}
      <header style={{ textAlign: "center", padding: "1rem 0", backgroundColor: "#000000", color: "#fff" }}>
        <h1 style={{ fontSize: "3rem", margin: 0 }}>Fit Controle</h1>
      </header>

      {/* Corpo Principal */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          padding: "2rem",
        }}
      >
        {/* Seção da Imagem */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <img
            src="work_in_progress.jpg"
            alt="Work in Progress"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        {/* Seção do Texto */}
        <div style={{ flex: 1, textAlign: "left", padding: "1rem" }}>
          <h1 style={{ color: "#2c3e50", fontSize: "2.5rem" }}>
            Transforme a gestão do seu espaço fitness
          </h1>
          <p style={{ fontSize: "1.2rem", marginTop: "1rem", lineHeight: "1.6" }}>
          Uma nova solução de gestão está chegando para revolucionar o jeito como 
          academia e Centros de treinamento operam. Com ferramentas intuitivas e recursos 
          sob medida, você terá mais tempo para focar nos seus clientes e alcançar 
          resultados incríveis. Seja parte dessa evolução!
          </p>
          <p style={{ fontSize: "1.2rem", marginTop: "1rem", lineHeight: "1.6" }}>
          Lançamento em breve!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
